.footer {
  /* background-color: #333; */
  
  padding: 2rem 0;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
}

.footer-text {
  font-size: 1.2rem;
  margin: 0;
  
}

.footer-links {
  list-style: none;
  display: flex;
  margin: 0;
}

.footer-links li {
  margin-right: 1.5rem;
}

.footer-links a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
  /* color: #ccc; */
}
