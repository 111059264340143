*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.top-container{
   position: relative;
   
}

.headerContainer{
   display: flex;
   justify-content: space-between;
   height: 100px;
   width: 100%;
   background-color: red;
   background-color: #FFFFFF;
   position: fixed;
   top: 0%;
   font-family: Poppins,Roboto,Helvetica,Arial,sans-serif;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-weight: 100;
    box-shadow: 5px 6px 10px #d0c9c9;
   
}

.headerContainer .left{
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerContainer .left .logo{
    /* margin: 0;
    height: 0; */
    width: 0;
    width: 200px;
    height: 130px;
    /* border: 2px solid black; */
    padding: 10px;
}

.headerContainer .right{
    display: flex;
    width: 300px;
    justify-content: space-between;
    margin-top:25px;
}

.contact{
    margin-top: 10px;
}

